<template>
<div class="container1150">
<header><strong>物联感知平台</strong></header>
<main>
<p>● · 设备管理：查看设备在地图上的分布，并查看所有设备信息</p>
<p>
                ● ·
                监测数据查看：查看各监测设备数据分布频率和实时数据、历史对比数据（包括对比、同比、环比）
            </p>
<p>● · 监测数据清洗：针对异常值、重复值、突变值进行修正</p>
<p>
                ● ·
                监测数据预警：设置预警参数进行实时预警，并结合外业工单进行预警方案发布和执行
            </p>
<div class="img5"></div>
<div class="img1"></div>
<div class="img2"></div>
<div class="img3"></div>
<div class="img4"></div>


</main>
</div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        .text-img {
            display: flex;
            justify-content: space-around;
            p {
                width: 40%;
                padding: 50px 0 0 20px;
                box-sizing: border-box;
            }
            .img1 {
                // width: 100%;
                width: 500px;
                height: 300px;
                margin: -60px auto 0;
                background-image: url("/img/product/6-3.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }

        .img-list {
            display: flex;
            width: 100%;
            .img1 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/7-1.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img2 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/7-2.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        .img1 {
            // width: 100%;
            width: 600px;
            height: 500px;
            margin: 10px auto;
            background-image: url("/img/product/16-1.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img2 {
            width: 600px;
            height: 500px;
            margin: 10px auto;
            background-image: url("/img/product/16-2.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img3 {
            width: 600px;
            height: 500px;
            margin: 10px auto;
            background-image: url("/img/product/16-3.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img4 {
            width: 600px;
            height: 500px;
            margin: 10px auto;
            background-image: url("/img/product/16-4.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img5 {
            width: 1100px;
            height: 500px;
            margin: 10px auto;
            background-image: url("/img/product/16-5.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>